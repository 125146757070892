<template>
  <div class="w-screen bg-white min-h-screen pt-24">
    <div class="container mx-auto">
      <div class="text-justify font-serif">
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <b>AVISO DE PRIVACIDAD</b>
        <br /><br />
        Responsable de la protección de sus datos personales Bufete de la Garza,
        S. C., con domicilio en Humboldt 121, Colonia Del Valle, C.P. 78200 en
        la Ciudad de San Luis Potosí, SLP, México, es responsable de recabar sus
        datos personales, del uso que se le dé a los mismos y de su protección.
        <br /><br />¿Para qué fines recabamos y utilizamos sus datos personales?
        <br /><br />Sus datos personales serán utilizados para proveer los
        servicios y productos requeridos por usted; Informar sobre cambios o
        nuevos productos o servicios que estén relacionados con el contratado o
        adquirido por el cliente; Dar cumplimiento a obligaciones contraídas con
        nuestros clientes; Evaluar la calidad del servicio, y Realizar estudios
        internos sobre hábitos de consumo. <br /><br />¿Qué datos personales
        obtenemos y de dónde?<br /><br />
        Para las finalidades señaladas en el presente aviso de privacidad,
        podemos recabar sus datos personales de distintas formas: cuando usted
        nos los proporciona directamente y cuando obtenemos información a través
        de otras fuentes que están permitidas por la ley. <br /><br />Datos
        personales que recabamos de forma directa <br /><br />Recabamos sus
        datos personales de forma directa cuando usted mismo nos los proporciona
        por diversos medios, como cuando participa en nuestras promociones o nos
        da información con objeto de que le prestemos un servicio. Los datos que
        obtenemos por este medio pueden ser, entre otros: <br /><br />- Datos de
        identificación: nombre, domicilio particular, fecha de nacimiento, CURP,
        número de teléfono, fijo o celular, fax, correo electrónico. <br />-
        Datos de facturación: domicilio fiscal, RFC. <br />- Datos laborales,
        tales como: puesto, domicilio, correo electrónico, número de teléfono,
        fijo o móvil y fax. <br /><br />Datos personales que recabamos a través
        de otras fuentes <br /><br />Podemos obtener información de usted de
        otras fuentes permitidas por la ley, tales como los directorios
        telefónicos o laborales. Los datos que obtenemos por estos medios pueden
        ser, entre otros: <br /><br />- Datos de identificación: nombre,
        domicilio particular, número de teléfono, fijo o celular, fax, correo
        electrónico. <br />- Datos laborales, tales como: puesto, domicilio,
        correo electrónico, número de teléfono, fijo o móvil y fax.
        <br /><br />¿Cómo acceder o rectificar sus datos personales o cancelar u
        oponerse a su uso? <br /><br />Usted tiene derecho de acceder,
        rectificar y cancelar sus datos personales, así como de oponerse al
        tratamiento de los mismos o revocar el consentimiento que para tal fin
        nos haya otorgado, a través de los procedimientos que hemos
        implementado. Para conocer dichos procedimientos, los requisitos y
        plazos, se puede poner en contacto con nuestro: <br /><br />Departamento
        de Protección de Datos Humboldt 121, Col. Del Valle C.P. 78200 San Luis
        Potosí, México E-mail: privacidad@bdlg.mx Teléfono: +52 (444) 8114069
        <br /><br />Transferencias de datos personales <br /><br />Bufete de la
        Garza, S.C. no venderá, cederá o transferirá sus datos personales a
        terceros ajenos a Bufete de la Garza, S.C. sin su consentimiento previo.
        Sin embargo, podrá transferir sus datos personales cuando dicha
        transferencia esté prevista en la Ley. <br /><br />Si usted desea dejar
        de recibir mensajes promocionales de nuestra parte puede solicitarlo a
        través del correo electrónico privacidad@bdlg.mx <br /><br />Cualquier
        modificación a este aviso de privacidad podrá consultarla en
        <a href="https://bdlg.mx/#/privacidad" target="_blank"
          >https://bdlg.mx/#/privacidad</a
        >
        <br /><br />Fecha última actualización [8/Febrero/2013]
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/components/Api";
export default {
  name: "HelloWorld",
  components: {},
  props: ["empresa"],
  mounted() {
    Api.$on("setLang", this.setLang);
  },
  methods: {
    setLang(s) {
      this.lang = s;
      console.log("setlang?", s);
      this.$forceUpdate();
    },
  },
  data() {
    return {
      esMovil: window.innerWidth / window.innerHeight > 1.5,
      nombre: "BDLG",
      lang: Api.obtenerLocal("lang") || "es",
      botonVerMas: {
        es: "Ver más",
        en: "More",
      },
      nosotrosTitulo: {
        es: "Lo que nos mueve son nuestros clientes",
        en: "Our clients are what moves us",
      },
      nosotrosSubtitulo: {
        es: "Somos una firma de abogados con más de 35 años de experiencia en diferentes ramas del derecho. <br /><br />Nuestra filosofía es prestar servicios profesionales e impecables para generar soluciones y alternativas confiables para nuestros amplio sector de clientes y colaboradores todo ello apegado a las normas de nuestra ética profesional. <br /><br />Actualmente contamos con dos despachos, uno en la ciudad de San Luis Potosí y otro en la Ciudad de México en los que se atiende a clientes mexicanos y extranjeros. ",
        en: "We are a law firm with over 35 years of experience in multiple law areas. <br /><br />Our philosophy is to render professional, impecable services in order to provide trustworthy alternatives and solutions for our wide range of clients and partners, all of that aligned with our ethics normative. <br /><br />We have currently two branch offices, one in San Luis Potosi, and another in Mexico City, both of them attending local and foreign clients. ",
      },
      tituloMision: {
        es: "Nuestra Misión",
        en: "Our Mission",
      },
      tituloVision: {
        es: "Nuestra Visión",
        en: "Our Vision",
      },
      tituloValores: {
        es: "Nuetros valores",
        en: "Our values",
      },
      mision: {
        es: "Nuestra misión es prestar servicios profesionales en las diferentes ramas del derecho, de manera responsable, honesta, eficiente, y con un alto grado de prestigio, equidad y justicia, generando soluciones y alternativas confiables para nuestros clientes. <br /><br />Buscamos mantenernos al día en el conocimiento jurídico y formación continua de nuestras especialidades para poder responder de manera eficiente a nuestros clientes, así como, lograr la realización personal y profesional de nuestros miembros, dentro de los más altos estándares éticos y de excelencia.",
        en: "Our mission is to render professional services in multiple law areas, being responsible, honest and efficient, and with the highest grade of prestige, equity and justice, providing trustworthy solutions and alternatives to our customers. <br /><br />We are looking for keeping up to date in legal knowledge and skills in all our specialties in order to respond efficiently to our clients, and in order to achieve professional and personal realization of our firm members, among the hightest excelence and ethics standards.",
      },
      vision: {
        es: "Aspiramos a ser una de las firmas de mayor transcendencia jurídica y social, generando para nuestros clientes, socios y colaboradores, un crecimiento profesional y así ser reconocidos por nuestros altos estándares de calidad y profesionalidad.",
        en: "We aim to be one of the greatest legal and social transcendence, allowing professional growth to our clients, associates and partners, thus being recognized for our high quality standards.",
      },
      valores: {
        es: "Nuestra filosofía es prestar servicios profesionales e impecables regidos por la honestidad, la lealtad y con la certeza de ofrecer estrategias que brindan una solución efectiva a las necesidades legales, todo ello, apegado a las normas de nuestra ética profesional.<br /><br />El equipo de Bufete de la Garza es reconocido por la calidad y la integridad que se ha brindado de generación a generación así como por su prestigio y el de sus clientes. <br /><br />Nuestra organización está plenamente consciente y comprometida con la sociedad, es así, que como parte de nuestra responsabilidad social participamos activamente con la fundación 'Renace', la cual tiene como objetivo defender los derechos humanos de personas en situación de pobreza a través de representación jurídica, tratamiento psicológico y bolsa de trabajo.<br /><br />Nos mantenemos en constante innovación tanto en las soluciones legales como en la infraestructura y tecnología, optimizando nuestros recursos necesarios para proporcionar a nuestros clientes un servicio de calidad y a la vanguardia, contando con aplicaciones y herramientas dinámicas(Software de gestión de casos y horas, base de datos jurídica, big data e Inteligencia artificial y telecomunicaciones). ",
        en: "Nuestra filosofía es prestar servicios profesionales e impecables regidos por la honestidad, la lealtad y con la certeza de ofrecer estrategias que brindan una solución efectiva a las necesidades legales, todo ello, apegado a las normas de nuestra ética profesional.<br /><br />El equipo de Bufete de la Garza es reconocido por la calidad y la integridad que se ha brindado de generación a generación así como por su prestigio y el de sus clientes. <br /><br />Nuestra organización está plenamente consciente y comprometida con la sociedad, es así, que como parte de nuestra responsabilidad social participamos activamente con la fundación 'Renace', la cual tiene como objetivo defender los derechos humanos de personas en situación de pobreza a través de representación jurídica, tratamiento psicológico y bolsa de trabajo.<br /><br />Nos mantenemos en constante innovación tanto en las soluciones legales como en la infraestructura y tecnología, optimizando nuestros recursos necesarios para proporcionar a nuestros clientes un servicio de calidad y a la vanguardia, contando con aplicaciones y herramientas dinámicas(Software de gestión de casos y horas, base de datos jurídica, big data e Inteligencia artificial y telecomunicaciones). ",
      },
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.helloimg {
  display: block;
  padding: 2em;
  width: 30vw;
  min-width: 280px;
}

.text-shadow-w {
  text-shadow: 2px 2px 4px #333;
}

.tilemvv,
.tilemvv img {
  height: 50vh;
  max-height: 326px;
}

.tilemvv img {
  width: 100% !important;
}

.project-text {
  height: 100%;
  overflow-y: scroll;
  //border: 2px solid #999999;
}

.logoheader {
  position: absolute;
  top: 10%;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  max-height: 80%;
  z-index: 100;
}

.membresia-section {
  background-color: white;
  width: 100vw;
  min-height: 100vh;
  padding-top: 10%;
  padding-bottom: 10%;
}
</style>
